<template>
    <footer class="bg-primary text-white">
        <div class="px-4 lg:px-12 md:container md:mx-auto py-8 lg:py-12">
            <div class="grid lg:grid-cols-3 gap-12 lg:gap-24">
                <div>
                    <div class="logo">
                        <img
                            src="~/assets/images/logo-light.png"
                            alt="logo"
                            loading="lazy"
                        />
                    </div>
                    <p class="mt-8 font-thin">
                        Need it, want it, find it! County Mall in Crawley, the
                        one-stop shopping hotspot based in Sussex with it’s huge
                        range of retailers under one roof – more than 80 in
                        total, offering over 200 well-known brands across
                        fashion, beauty, interiors & lifestyle, there is
                        something for everyone.
                    </p>
                    <div class="mt-8">
                        <nuxt-link
                            to="https://x33q5.app.goo.gl/vMcdN"
                            target="_blank"
                        >
                            <button class="btn btn-white">
                                Sign up to our newsletter
                            </button>
                        </nuxt-link>
                    </div>
                </div>
                <div>
                    <h5 class="text-2xl font-medium text-white">Quick Links</h5>
                    <ul class="mt-8 quick-links">
                        <li>
                            <nuxt-link to="/about-us" class="text-lg"
                                >About Us</nuxt-link
                            >
                        </li>
                        <li>
                            <nuxt-link to="/services" class="text-lg"
                                >Services</nuxt-link
                            >
                        </li>
                        <li>
                            <nuxt-link to="/jobs" class="text-lg"
                                >Jobs</nuxt-link
                            >
                        </li>
                        <li>
                            <nuxt-link
                                to="/commercial-and-leasing"
                                class="text-lg"
                                >Commercial & leasing</nuxt-link
                            >
                        </li>
                        <li>
                            <nuxt-link to="/contact-us" class="text-lg"
                                >Contact Us</nuxt-link
                            >
                        </li>
                    </ul>
                    <div class="arora-logo mt-8">
                        <img
                            src="~/assets/images/arora-logo.png"
                            alt="arora logo"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div class="">
                    <h5 class="text-2xl font-medium text-white">
                        Opening Hours
                    </h5>
                    <div class="mt-8">
                        <div
                            v-if="mallOpeningHours"
                            class="grid grid-cols-2 gap-x-8 gap-y-4"
                        >
                            <div
                                v-for="(
                                    oh, index
                                ) in mallOpeningHours.OpeningHours"
                                :key="index"
                            >
                                <h6 class="text-xl text-white mb-2">
                                    {{ oh.DayOfWeek }}
                                </h6>
                                <p
                                    v-if="!oh.ClosedAllDay"
                                    class="text-white font-thin"
                                >
                                    {{ `${oh.OpenTime} - ${oh.CloseTime}` }}
                                </p>
                                <p
                                    v-if="oh.ClosedAllDay"
                                    class="text-white font-thin"
                                >
                                    Closed all day
                                </p>
                                <p
                                    v-if="oh.DayOfWeek === 'Thursday'"
                                    class="text-xs mt-1 text-slate-200 font-thin"
                                >
                                    *Primark open until 8pm
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div
                class="flex justify-between items-center flex-wrap gap-y-8 lg:gap-0"
            >
                <p>Copyright © {{ currentYear }} County Mall</p>
                <div class="social">
                    <nuxt-link
                        to="https://www.instagram.com/countymall"
                        target="_blank"
                    >
                        <img
                            src="/images/icons/instagram.svg"
                            alt="instagram"
                            loading="lazy"
                        />
                    </nuxt-link>
                    <nuxt-link
                        to="https://www.facebook.com/countymall/"
                        target="_blank"
                    >
                        <img
                            src="/images/icons/facebook.svg"
                            alt="facebook"
                            loading="lazy"
                        />
                    </nuxt-link>
                    <nuxt-link
                        to="https://twitter.com/County_Mall"
                        target="_blank"
                    >
                        <img
                            src="/images/icons/twitter.svg"
                            alt="twitter"
                            style="padding: 3px"
                            loading="lazy"
                        />
                    </nuxt-link>
                    <nuxt-link
                        to="https://www.youtube.com/channel/UCen3NeQGtCfJV5rTJW03NNw"
                        target="_blank"
                    >
                        <img
                            src="/images/icons/youtube.svg"
                            alt="youtube"
                            loading="lazy"
                        />
                    </nuxt-link>
                </div>
                <ul class="other-links">
                    <li>
                        <nuxt-link to="/accessibility">Accessibility</nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/terms-and-conditions"
                            >Terms and Conditions</nuxt-link
                        >
                    </li>
                    <li>
                        <nuxt-link to="/privacy-policy"
                            >Privacy Policy</nuxt-link
                        >
                    </li>
                    <li>
                        <nuxt-link to="/sitemap">Site Map</nuxt-link>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script setup>
import { computed } from 'vue';
const config = useRuntimeConfig();
const currentYear = computed(() => new Date().getFullYear());
const mallOpeningHours = ref(null);
const { data: response } = await useFetch(
    config.public.baseURl + '/opening-hours',
    {
        transform: (response) => {
            return response.map((oh) => ({
                StoreName: oh.StoreName,
                OpeningHours: oh.OpeningHours,
            }));
        },
        query: { StoreName: 'County Mall' },
    }
);
mallOpeningHours.value = response.value[0];
</script>

<style lang="scss" scoped>
footer {
    color: $white;
    .logo {
        width: 100%;
        img {
            height: 80px;
        }
    }
    ul.quick-links {
        li {
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .social {
        display: flex;
        justify-content: space-between;
        column-gap: 1.25rem;
        a {
            width: 36px;
            height: 36px;
            border-radius: 18px;
            background-color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 32px;
            }
        }
    }
    ul.other-links {
        li {
            display: inline;
            margin-right: 16px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .arora-logo {
        img {
            height: 80px;
        }
    }
}
</style>
