<template>
    <div id="default-layout">
        <Header />
        <main>
            <slot />
        </main>
        <Footer />
    </div>
</template>
<script setup></script>
<style lang="scss" scoped>
#default-layout {
    main {
        margin-top: 80px;
        @media (max-width: 767px),
            (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
            margin-top: 56px;
        }
    }
}
</style>
